import { render, staticRenderFns } from "./Logs.vue?vue&type=template&id=49e5d55d&scoped=true&"
import script from "./Logs.vue?vue&type=script&lang=js&"
export * from "./Logs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e5d55d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\sinan\\Desktop\\Core Projects\\CoreOS-V4-develop\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49e5d55d')) {
      api.createRecord('49e5d55d', component.options)
    } else {
      api.reload('49e5d55d', component.options)
    }
    module.hot.accept("./Logs.vue?vue&type=template&id=49e5d55d&scoped=true&", function () {
      api.rerender('49e5d55d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/settings/logs/Logs.vue"
export default component.exports