var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("settings.logs.logs"),
          actionButtonIcon: "fa fa-upload",
          actionButtonDesc: _vm.$t("settings.logs.export")
        },
        on: { action: _vm.csvExport }
      }),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.getLogMessages } },
        [
          _c("i", { staticClass: "fa fa-recycle" }),
          _vm._v(" " + _vm._s(_vm.$t("settings.logs.refresh")))
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { "margin-top": "20px" },
          attrs: {
            data: _vm.$store.getters.logs,
            stripe: "",
            border: "",
            width: "500px",
            height: "600"
          },
          on: { "filter-change": _vm.filterChanged }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "tag",
              label: _vm.$t("settings.logs.tag"),
              width: "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "level",
              label: _vm.$t("settings.logs.level"),
              width: "200",
              filters: _vm.levels,
              "filter-method": _vm.filterLevel
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: { type: _vm.filterStyleClass(scope.row.level) }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.levels.find(function(level) {
                              return level.value == scope.row.level
                            }).text
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "message", label: _vm.$t("settings.logs.message") }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createdAt",
              label: _vm.$t("settings.logs.create-date"),
              width: "200"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }