<template>
  <div>

    <app-navigation :title="$t('settings.logs.logs')" actionButtonIcon="fa fa-upload"  :actionButtonDesc="$t('settings.logs.export')" @action="csvExport"></app-navigation>


    <el-button type="primary" @click="getLogMessages"><i class="fa fa-recycle"></i> {{ $t('settings.logs.refresh') }}</el-button>


    <el-table  style="margin-top: 20px" :data="$store.getters.logs" stripe v-loading="loading" border width="500px" height="600" @filter-change="filterChanged">

      <el-table-column
              prop="tag"
              :label="$t('settings.logs.tag')"
              width="200">

      </el-table-column>

      <el-table-column
              prop="level"
              :label="$t('settings.logs.level')"
              width="200"
              :filters="levels"
              :filter-method="filterLevel">

        <template slot-scope="scope">
          <el-tag :type="filterStyleClass(scope.row.level)">{{ levels.find(level => level.value == scope.row.level).text }}</el-tag>
        </template>

      </el-table-column>

      <el-table-column
              prop="message"
              :label="$t('settings.logs.message')">
      </el-table-column>

      <el-table-column
              prop="createdAt"
              :label="$t('settings.logs.create-date')"
              width="200">
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation';
  export default {
    name: "Logs",

    components: {
      'app-navigation': Navigation
    },

    data: function () {
      return {
        loading: false,
        levels: [
          { text: this.$t('settings.logs.levels[0]'), value: "V" },
          { text: this.$t('settings.logs.levels[1]'), value: "D" },
          { text: this.$t('settings.logs.levels[2]'), value: "I" },
          { text: this.$t('settings.logs.levels[3]'), value: "W" },
          { text: this.$t('settings.logs.levels[4]'), value: "E" },
        ],
        filters: ["V", "D", "I", "W", "E"],
      }
    },

    methods: {
      filterLevel(value, row){
        return row.level == value
      },

      filterStyleClass(level){
        switch (level) {
          case "V":
            return "info"
          case "I":
            return "success"
          case "W":
            return "warning"
          case "E":
            return "danger"
          default:
            return ""
        }
      },

      filterChanged(filters){
        this.filters = Object.values(filters)[0]
        console.log(this.filters)
      },

      csvExport() {
        let arrData = this.$store.getters.logs.filter(log => this.filters.includes(log.level))

        // let csvContent = "data:text/csv;charset=utf-8,";
        // csvContent += [
        //   Object.keys(arrData[0]).join(";"),
        //   ...arrData.map(item => Object.values(item).join(";"))
        // ]
        //   .join("\n")
        //   .replace(/(^\[)|(\]$)/gm, "");

        var logs = ""
        arrData.forEach(log => {
          logs += log.createdAt + " " + log.level + "/" + log.tag + ": " + log.message + "\n";
        })



        //const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.href = 'data:text/plain;charset=UTF-8,' + encodeURIComponent(logs);


        //link.setAttribute("href", data);
        link.setAttribute("download", "logs.txt");
        link.click();
      },

      getLogMessages(){
        this.loading = true
        this.$store.dispatch("getLogMessages").then(message => {
          this.loading = false
        }).catch(reason => {
          this.loading = false
        })
      }
    },

    created(){
      this.getLogMessages()
    }
  }
</script>

<style scoped>

</style>